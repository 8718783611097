<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <product-group />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <product-code />
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <title-text />
        </b-col>
        <b-col
          cols="12"
        >
          <summary-text />
        </b-col>
        <b-col
          cols="12"
        >
          <content-text />
          <technical-text />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-ordering />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-status />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <price-form />
      </b-col>
      <b-col
        cols="12"
      >
        <image-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import { required } from '@validations'
import ProductGroup from '@/views/Admin/Products/elements/ProductGroup.vue'
import ProductCode from '@/views/Admin/Products/elements/ProductCode.vue'
import TitleText from '@/views/Admin/Products/elements/TitleText.vue'
import ContentText from '@/views/Admin/Products/elements/ContentText.vue'
import TechnicalText from '@/views/Admin/Products/elements/TechnicalContent.vue'
import ProductOrdering from '@/views/Admin/Products/elements/ProductOrdering.vue'
import ProductStatus from '@/views/Admin/Products/elements/ProductStatus.vue'
import ImageCard from '@/views/Admin/Products/elements/ImageCard.vue'
import SummaryText from '@/views/Admin/Products/elements/SummaryText.vue'
import PriceForm from '@/views/Admin/Products/Forms/PriceForm.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    ProductGroup,
    ProductCode,
    TitleText,
    ContentText,
    ProductOrdering,
    ProductStatus,
    ImageCard,
    SummaryText,
    TechnicalText,
    PriceForm,
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
  },
}
</script>
