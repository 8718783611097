<template>
  <b-form-group
    label="Ürün Grubu"
    label-for="id_product_categories"
  >
    <v-select
      id="id_product_categories"
      v-model="itemData.id_product_categories"
      :options="productGroups"
      label="title"
      :reduce="item => item.id"
      placeholder="Ürün Grubu"
    >
      <template v-slot:option="option">
        <div class="font-small-2">
          {{ option.pcode }}
        </div>
        <div>
          {{ option.title }}
        </div>
        <div>
          <small class="text-warning">{{ option.main_group }}</small>
        </div>
      </template>
      <template v-slot:selected-option="option">
        {{ option.title }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductGroup',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
}
</script>
