<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Ürün Bilgisi</span>
        </template>
        <info-form />
      </b-tab>
      <b-tab
        :disabled="!itemData.id"
      >
        <template #title>
          <feather-icon icon="CameraIcon" />
          <span>Fotoğraflar</span>
        </template>
        <image-gallery />
      </b-tab>
      <b-tab
        :disabled="!itemData.id"
      >
        <template #title>
          <feather-icon icon="VideoIcon" />
          <span>Videolar</span>
        </template>
        <video-gallery />
      </b-tab>
      <b-tab :disabled="!itemData.id">
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Dosyalar</span>
        </template>
        <file-form />
      </b-tab>
    </b-tabs>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue'
import InfoForm from '@/views/Admin/Products/Forms/InfoForm.vue'
import ImageGallery from '@/views/Admin/Products/Forms/PhotoGallery.vue'
import VideoGallery from '@/views/Admin/Products/Forms/VideoGallery.vue'
import FileForm from '@/views/Admin/Products/Forms/FileForm.vue'
import PriceForm from '@/views/Admin/Products/Forms/PriceForm.vue'

export default {
  name: 'ItemForm',
  components: {
    BTabs,
    BTab,
    BButton,
    InfoForm,
    PriceForm,
    ImageGallery,
    VideoGallery,
    FileForm,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
  },
  created() {
    this.getProductGroups()
  },
  methods: {
    getProductGroups() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_categories.id AS id',
          'product_categories.title AS title',
          'pc.title AS main_group',
        ],
        where: {
          'product_categories.id_product_categories !=': null,
        },
      })
    },
  },
}
</script>
