<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Video Galerisi</b-card-title>
        <div>
          <b-button
            variant="primary"
            class="mr-1"
            @click="toggleVideoForm"
          >
            <FeatherIcon icon="UploadIcon" />
            Video Ekle
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            v-for="(item,index) in dataItem.videos"
            :key="index"
            cols="12"
            md="4"
          >
            <div class="border p-1 rounded">
              <b-img
                :src="imageUrl + '/media/products_media/' + item.thumb"
                fluid-grow
              />
              <div class="font-weight-bold text-center mt-1 border-bottom pb-1">
                {{ item.title }}
              </div>
              <div class="mt-1 d-flex justify-content-center">
                <b-button
                  variant="flat-warning"
                  pill
                  class="btn-icon mr-1"
                  @click="editVideo(item)"
                >
                  <FeatherIcon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  pill
                  class="btn-icon ml-1"
                  @click="deleteVideo(item.id)"
                >
                  <FeatherIcon icon="TrashIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <video-form
      v-if="videoForm"
      :close-video-form="toggleVideoForm"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BImg,
} from 'bootstrap-vue'
import VideoForm from '@/views/Admin/Products/Forms/VideoForm.vue'

export default {
  name: 'VideoGallery',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BImg,
    VideoForm,
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      videoForm: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
    videoItem() {
      return this.$store.getters['products/videoItem']
    },
  },
  methods: {
    editVideo(data) {
      this.videoForm = true
      this.videoItem.id = data.id
      this.videoItem.title = data.title
      this.videoItem.image = data.image
      this.videoItem.thumb = data.thumb
      this.videoItem.video_embed = data.video_embed
      this.videoItem.id_products = data.id_products
    },
    deleteVideo(id) {
      this.$store.dispatch('products/deleteVideo', id)
    },
    toggleVideoForm() {
      this.videoForm = !this.videoForm
      this.$store.commit('products/RESET_VIDEO_ITEM')
    },
    removeImage(data, index) {
      if (data.id) {
        this.$store.dispatch('products/removeVideosData', data.id)
          .then(response => {
            if (response) {
              this.dataItem.videos.splice(index, 1)
            }
          })
      } else {
        this.dataItem.videos.splice(index, 1)
      }
    },
  },
}
</script>
