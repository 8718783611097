<template>
  <b-form-group
    label="Özet Açıklama"
    label-for="summary"
  >
    <validation-provider
      #default="{ errors }"
      name="Özet Açıklama"
      rules="required"
    >
      <b-form-textarea
        id="title"
        v-model="itemData.summary"
        placeholder="Özet Açıklama"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'SummaryText',
  components: {
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
  },
}
</script>
