<template>
  <b-card title="Video Ekle">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Video Adı"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="itemData.title"
            placeholder="Video Adı"
          />
        </b-form-group>
        <b-form-group
          label="Video Bağlantısı"
          label-for="url"
        >
          <b-form-input
            id="url"
            v-model="videoUrl"
            placeholder="Video Bağlantısı"
            @input="getEmbedCode"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-embed
          v-if="itemData.video_embed"
          type="iframe"
          aspect="16by9"
          :src="youtubeUrl + itemData.video_embed"
        />
        <div
          v-else
          class="bg-light py-5 text-center rounded text-muted"
        >
          <div class="my-2">
            <div>
              <FeatherIcon icon="VideoIcon" />
            </div>
            <div>
              Video Önizleme
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-img
          v-if="itemData.thumb"
          :src="imageUrl + '/media/products_media/' + itemData.thumb"
          thumbnail
          center
        />
        <div
          v-else
          class="bg-light py-5 text-center rounded text-muted"
        >
          <div class="my-2">
            <div>
              <FeatherIcon icon="ImageIcon" />
            </div>
            <div>
              Kapak Görseli
            </div>
          </div>
        </div>
        <div class="text-center mt-1">
          <b-button
            variant="primary"
            @click="setModal('video_gallery')"
          >
            <FeatherIcon icon="UploadIcon" />
            Görsel Yükle
          </b-button>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-between mt-2">
          <template>
            <b-button
              variant="success"
              :disabled="!itemData.video_embed"
              @click="addVideo"
            >
              <FeatherIcon icon="SaveIcon" /> {{ itemData.id? 'Video Güncelle' : 'Video Ekle' }}
            </b-button>
            <b-button
              variant="danger"
              @click="cancelVideo"
            >
              <FeatherIcon icon="XCircleIcon" /> Vazgeç
            </b-button>
          </template>
        </div>
      </b-col>
    </b-row>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      @uploadImage="setUploadImage"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BEmbed, BImg,
} from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'VideoForm',
  components: {
    CropperImage,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BEmbed,
    BImg,
  },
  props: {
    closeVideoForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      videoUrl: null,
      youtubeUrl: 'https://www.youtube.com/embed/',
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/videoItem']
    },
    product() {
      return this.$store.getters['products/dataItem']
    },
  },
  methods: {
    addVideo() {
      if (this.itemData.id) {
        const itemIndex = this.product.videos.findIndex(p => p.id === this.itemData.id)
        this.product.videos[itemIndex] = this.itemData
      } else {
        this.product.videos.push(this.itemData)
      }
      this.videoUrl = null
      this.$store.commit('products/RESET_VIDEO_ITEM')
      this.closeVideoForm()
    },
    cancelVideo() {
      this.videoUrl = null
      this.$store.commit('products/RESET_VIDEO_ITEM')
      this.closeVideoForm()
    },
    setModal(type) {
      if (type === 'video_gallery') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'video-cover-image.jpg',
          folder: 'products_media',
          ratio: 16 / 9,
          type: 'gallery',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      if (this.cropperSettings.type === 'gallery') {
        this.itemData.image = item.image
        this.itemData.thumb = item.thumb
      }
      this.cropperSettings.modalStatus = false
    },
    getEmbedCode() {
      this.$store.dispatch('videoGallery/getEmbedCode', this.videoUrl)
        .then(res => {
          this.itemData.video_embed = res.id
        })
    },
  },
}
</script>
