<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Dosyalar</b-card-title>
        <b-button
          variant="primary"
          size="sm"
          @click="getUpload"
        >
          Dosya Ekle
        </b-button>
      </b-card-header>
      <b-table
        :items="dataItem.files"
        :fields="fields"
      >
        <template #cell(control)="data">
          <b-button
            variant="flat-danger"
            pill
            class="btn-icon"
            @click="removeFile(data.item.id)"
          >
            <FeatherIcon icon="TrashIcon" />
          </b-button>
          <b-button
            variant="flat-success"
            pill
            class="btn-icon"
            :href="fileUrl + '/media/products_files/' + data.item.filename"
            target="_blank"
          >
            <FeatherIcon icon="DownloadIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-card
      v-if="uploadForm"
      no-body
    >

      <b-card-header>
        <b-card-title>Dosya Ekle</b-card-title>
      </b-card-header>
      <b-card-body>
        <pre-loading v-if="uploading" />
        <template v-if="!uploading">
          <b-form-input
            id="fileTitle"
            v-model="fileTitle"
            placeholder="Dosya başlığı"
          />

          <b-form-file
            id="files"
            v-model="uploadFiles"
            class="mt-1"
            placeholder="Bir dosya seçin veya buraya bırakın..."
            drop-placeholder="Dosyayı buraya bırakın..."
            browse-text="Dosya Seçin"
          />
          <div class="mt-1">
            <b-button
              variant="primary"
              :disabled="!uploadFiles"
              @click="uploadFile('file')"
            >
              <FeatherIcon icon="UploadIcon" />
              Dosya Yükle
            </b-button>
          </div>
        </template>

      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BTable, BButton, BCardBody, BFormFile, BFormInput,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'OptionForm',
  components: {
    PreLoading,
    BFormFile,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BButton,
    BCardBody,
    BFormInput,
  },
  data() {
    return {
      addOption: false,
      optionsID: [],
      options: [],
      uploadForm: false,
      uploadFiles: null,
      fileTitle: null,
      fileUrl: this.$store.state.app.baseURL,
      fields: [
        {
          key: 'title',
          label: 'Dosya Başlığı',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'width-50 text-nowrap text-right',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
    uploading() {
      return this.$store.getters['fileUpload/uploading']
    },
  },
  methods: {

    getUpload() {
      this.uploadForm = true
    },
    uploadFile(type) {
      let file = ''
      if (type === 'file') {
        file = this.uploadFiles
      }
      this.$store.dispatch('fileUpload/uploadFile', {
        upload_file: file,
        folder: 'products_files',
      })
        .then(response => {
          if (response.filename) {
            if (type === 'file') {
              this.dataItem.files.push({
                title: this.fileTitle,
                filename: response.filename,
                id_product: this.$route.params.id,
              })
            }
            this.fileTitle = null
            this.uploadForm = false
          }
        })
    },
    removeFile(id) {
      this.$store.dispatch('products/removeFileData', id)
        .then(async response => {
          if (response) {
            this.dataItem.options = await this.$store.dispatch('products/getDataList', {
              where: {
                'products.id': this.$route.params.id,
              },
            })
          }
        })
    },
  },
}
</script>
