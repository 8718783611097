<template>
  <div>
    <b-card title="Fiyatlar">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Liste Fiyatı"
            label-for="list_price"
          >
            <b-form-input
              id="list_price"
              v-model="dataItem.list_price"
              placeholder="Liste Fiyatı"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="İndirimli Fiyatı Fiyatı"
            label-for="discount_price"
          >
            <b-form-input
              id="discount_price"
              v-model="dataItem.discount_price"
              placeholder="İndirimli Fiyatı"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Döviz Türü"
            label-for="id_currencies"
          >
            <v-select
              id="id_currencies"
              v-model="dataItem.id_currencies"
              :options="currencies"
              label="title"
              :reduce="item => item.id"
              placeholder="Döviz Türü"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'PriceForm',
  components: {
    vSelect,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BCard,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
    currencies() {
      return this.$store.getters['currencies/dataList']
    },
  },
  created() {
    this.getCurrencies()
  },
  methods: {
    getCurrencies() {
      this.$store.dispatch('currencies/getDataList', {
        select: [
          'currencies.id AS id',
          'currencies.title AS title',
        ],
      })
    },

  },
}
</script>
